import React from "react"
import { motion } from "framer-motion"
import Snow from "./components/Snow"
import VimeoPlayer from "react-player/vimeo"
import Logo from "./components/Logo"

function App() {
    return <motion.div className="text-white text-center overflow-x-hidden">
        {/* Particles */}
        <Snow />

        {/* Main Content */}
        <div className="w-screen bg-cover bg-center h-screen" style={{ backgroundImage: "url(bg.jpg)" }}>
            <motion.div className="h-screen" initial={{ backgroundColor: "#000000FF" }} animate={{ backgroundColor: "#00000070" }} transition={{ duration: 2 }}>
                <div className="xl:w-1/2 w-4/5 h-full mx-auto flex flex-col">
                    {/* Happy Christmas */}
                    <div className="md:h-1/5 h-1/4 flex">
                        <motion.h1 className="font-dancingScript lg:text-8xl text-6xl py-4 m-auto" initial={{ y: -500 }} animate={{ y: 0, transition: { delay: 2 } }}>Happy Christmas!</motion.h1>
                    </div>

                    {/* Video */}
                    <div className="xl:h-1/2 md:h-2/5 h-1/4">
                        <div className="relative h-full">
                            <motion.div className="absolute top-0 bottom-0 left-0 right-0" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 3, duration: 1.5 }}>
                                <VimeoPlayer url="https://vimeo.com/654432908/75aea485f3" controls width="100%" height="100%" />
                            </motion.div>
                        </div>
                    </div>

                    {/* Message */}
                    <div className="mx-auto xl:w-5/6 overflow-hidden sm:text-base text-sm">
                        <motion.p className="pt-2" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 4, duration: 1.5 }}>
                            Dear parents, alumni and other friends of the school,
                            <br />
                            As we reflect on a couple of extraordinary and, at some times, very tough years, we look back at how far we have come as a school to overcome the immense hurdles presented by the pandemic. This is no doubt due to the continued supported by you: our friends of the school. So, as we look forward to hopefully a more normal new year, we thank you for all that you have done.
                            <br />
                            Merry Christmas on behalf of all of us here at St Paul’s Cathedral School and a happy new year!
                        </motion.p>
                        <motion.p className="text-right" initial={{ x: 500 }} animate={{ x: 0, transition: { delay: 5.5 } }}>
                            <em>- Simon Larter-Evans (Headmaster)</em>
                        </motion.p>
                    </div>

                </div>
            </motion.div>
        </div >

        {/* Footer */}
        <div className="bg-blue flex">
            <a className="mx-auto my-2" href="https://www.spcslondon.com" target="_blank" rel="noreferrer">
                <Logo />
            </a>
        </div>
    </motion.div >
}

export default App
