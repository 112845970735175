import React from "react"
import Particles from "react-tsparticles";

function Snow({ props }) {
    return <Particles options={{
        particles: {
            number: {
                value: 200,
                density: {
                    enable: true,
                    value_area: 800
                }
            },
            color: {
                value: "#ffffff"
            },
            shape: {
                type: "circle"
            },
            size: {
                value: 4,
                random: true
            },
            opacity: {
                value: 0.5,
                random: true
            },
            move: {
                enable: true,
                direction: "bottom",
                random: true,
                speed: 6,
                outMode: "out",
                attract: {
                    rotateX: 600,
                    rotateY: 1200
                }
            }
        }
    }} {...props}/>
}

export default Snow;